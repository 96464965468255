@import "colors.scss";

.administration {
    button.btn {
        border: 1px solid $default-border;
    }

    fieldset > legend  {
        margin-bottom: 0;
    }

    fieldset > :not(:first-child) {
        margin-top: 20px;
    }

    .integrity-test {
        margin-top: 20px;
        border: 1px solid $default-border;

        .integrity-test-title {
            font-size: 15px;
            font-weight: bold;
            padding: 8px;
        }

        .integrity-test-header {
            margin: 15px 5px;

            > .btn.selected {
                background-color: $light;
            }
        }
    }
}
